.MainMenuWrapper{
    position: absolute;
    display: flex;
    width: 280px;
    height: 100%;
    left: -260px;
    transition: 0.3s;
    z-index: 500;
}

.MainMenuWrapperHover{
    position: absolute;
    display: flex;
    width: 280px;
    height: 100%;
    left: 0px;
    transition: 0.3s;
    z-index: 500;
}

.MainMenuContentrHover{
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 260px;
    background-color: var(--bg-mainmenu);
    color: var(--text-color);
    box-shadow: 5px 0px 4px rgba(0, 0, 0, 0.265);    
    /*overflow: auto;
    scrollbar-width: none;*/
    transition: 0.3s;
    -ms-overflow-style: none;
}

.MainMenuButton{
    flex-grow: 0;
    flex-shrink: 0;
    width: 15px;
    height: 190px;
    writing-mode: vertical-rl;
    text-align: center;
    border-radius: 0 5px 5px 0;
    margin-top: 80%;
    background-color: var(--bg-mainmenu);
}

.MainMenuButton--Text{
    color: var(--subtext-color);
    font-size: 1.2rem;
    transform: rotate(180deg);
    line-height: 1.5;
}

.MenuHeader--Icon{
    padding-right: 5px;
}

.MenuHeader--Text{
    padding-top: 4px;
    font-size: 1.2rem;
    width: 70%;
    margin-top: 7px;
    font-weight: bold;
}

.MainMenueContent--Wrapper{
    display:flex;
    flex:1;
    flex-direction: column;
    overflow: auto;
    scrollbar-width: none;
}

.MainMenuItem{
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.MainMenuItem--Header{
    display: flex;
    width: 100%;
}

.MainMenuHeader{
    display: flex;
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: 10px;
}

.MainMenuItem--Icon{
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.MainMenuItem--Text{
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;  
    flex-basis: 180px;
    flex-grow: 0;
}

.MainMenuItem--Divider{
    border-top: 1px solid var(--main-menu-divider-color);
    width: 175px;
    margin-left: 50px;
    margin-top: 30px;
}

.MainMenuItem--Content{
    overflow-y: auto;    
    scrollbar-width: none;
    margin-right: 5px;
    min-width: 215px;
}

.MainMenuItem--Content::-webkit-scrollbar {
    display: none;
  }

.MainMenuItemContent--DashboardWrapper{
    display: flex;
    justify-content: space-between;
    max-width: 250px;
}

.MainMenuItemContent--DashboardDelete{
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    cursor: pointer;
}

.MainMenuItemContent--DashboardDelete:hover{
    color: var(--red)
}

.MainMenuItemContent--DashboardName{
    margin-left: 60px;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 1.2rem;
    text-align: justify;
    cursor: pointer;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 8px;
    padding-right: 8px;
}

.MainMenuItemContent--DashboardName:hover{
    background-color: var(--main-menu-hover-color);
    /*color: green;*/
}

.MainMenuItemContent--DashboardNameActive{
    margin-left: 60px;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 1.2rem;
    text-align: justify;
    cursor: pointer;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: bold;
}

.MainMenuItemContent--DashboardNameActive:hover{
    background-color: var(--main-menu-hover-color);
    /*color: green;*/
}
.MainMenuItem--DropDownContent{
    overflow-y: auto;
    scrollbar-width: none;
    max-height: 200px;
    margin-left: 15px;
}

.MainMenuItemContent--Header{
    display: flex;
    width: 170px;
    margin-left: 60px;
    align-items: baseline;
}
.MainMenuItemContent--Header:hover{
    background-color: var(--main-menu-hover-color);
}

.MainMenuItemContent--CheckboxHeader{
    display: flex;
    width: 170px;
    margin-left: 60px;
    height: 24px;
    margin-top: 10px;
}

.MainMenuItemContent--Name{
    margin-left: 60px;
    margin-top: 10px;
    font-size: 1.2rem;
    text-align: justify;
    cursor: pointer;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 8px;
}
.MainMenuItemContent--Name:hover{
    background-color: var(--main-menu-hover-color);
    /*color: green;*/
}

.MainMenuItemContent--Icon{
    font-size: 1.2rem;
    margin-right: 10px;
    cursor: pointer;
}

.MainMenuContent--Button{
    width: 52.5%;
    margin-left: 60px;
    margin-top: 20px;
    font-size: 1.2rem;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
    cursor: pointer;
    color: var(--main-menu-button-color);
}
.MainMenuContent--Button:hover{
    background-color: var(--main-menu-hover-color);
    color: var(--text-color);
}
.MainMenuContent--Button:active{
    background-color: var(--main-menu-button-press-color);
}

.MainMenuContent--DropdownName{
    width: 100%;
    font-size: 1.2rem;
    cursor: pointer;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 8px;
}

.MainMenuContent--Checkbox{
    width: 100%;
    margin-left: 60px;
    margin-top: 10px;
    font-size: 1.2rem;
    text-align: justify;
    cursor: pointer;
}

.MainMenuItemContent--CheckboxName{
    width: 100%;
    margin-left: 6px;
    font-size: 1.2rem;
    text-align: justify;
    line-height: 24px;
}

.MainMenuItemContent--DropdownSeperator{
    /*background-color: blueviolet;*/
    height: 10px;
}

.darkmodeSwitchBTN {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    font-size: 1rem;
    background: none;
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    color: var(--text-color);
    text-decoration: none;
    margin-top: 15px;
    margin-left: 80px;
  }
  
  .darkmodeSwitchBTN:hover {
    background: var(--main-menu-hover-color);
    text-decoration: none;
  }












