.mainWrap{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;  
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 20px;
  box-sizing: border-box;  
}

.themeContainer{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;  
  box-sizing: border-box;  
  background-color: var(--bg-dark)
}

.AppTest{
  position: absolute;
  z-index: 600;
  background-color: rgba(96, 102, 108, 0.900);
  height: 100%;
  width: 100%;
}

.modal {
  font-size: 1.2rem;
  background-color: var(--bg-widget);
  border: 0px solid var(--border-color);
  border: none !important;
  color: var(--text-color);
  border-radius: 5px;
  padding: .5rem;
  -webkit-animation: .3s cubic-bezier(.38,.1,.36,.9) forwards a;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.modal > .content {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  width: calc(100% - 40px);
}

.modal > .content > ul{
  font-size: 1rem;
  color: var(--subtext-color);
}

.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.modal > .actions > .button {
  background-color: #fff;
  border: 3px dashed var(--ifm-link-color);
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: Roboto,Arial,sans-serif;
  font-size: 1.2rem;
  letter-spacing: 1.0px;
  line-height: 2em;
  margin: 10px 15px;
  outline: 0;
  padding: 0 30px;
  position: relative;
  text-transform: uppercase;
  transition: .1s;
  border-radius: 0px;
  font-weight: var(--ifm-button-font-weight);
  text-align: center;
}

.modal > .actions > .button:hover{
  background-color: var(--main-menu-hover-color);
  color: var(--text-color);
}

.popup-content{
  border-radius: 5px;
  padding: .5rem;
  -webkit-animation: .3s cubic-bezier(.38,.1,.36,.9) forwards a;
  background: none !important;
  border: none !important;
}

.dashLoading{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.saveModalStyle{
  text-align: center;
}

.saveModalInput{
  text-align: center;
}
