.githubWidget--ContentWrap{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    font-size: 1rem;
    margin: 0px 15px 15px 15px;
}

.githubWidget-wrapper{
    /*background-color: var(--hover-color);*/
}

.github-table {
  border-collapse: separate;
  border-spacing: 0 1em;
  width: 100%;
  margin-top: -10px;
}

.githubWidget-arrow{
  margin-left: 5px;
}

.github-tableHead {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    /*background-color: var(--hover-color);*/
    background-color: var(--cloudflare-graphheader);
    z-index: 1;
}

.github-tableRow{
  background-color: var(--table-row-bg-color);
}

.github-tableRowRED{
  background-color: var(--table-row-bg-colorRED);
}

.github-tableRowYELLOW{
  background-color: var(--table-row-bg-colorYELLOW);
}

.github-tableCell {
  padding: 15px;
  text-align: left;
}

.github-Table-SearchWrapper {
  display: flex;
  flex: 1;
}

.github-Table-SearchInput {
  margin-top: 5px;
  flex: 1;
  width: 100%; /* Fügt eine Breitenangabe hinzu */
  max-width: 150px;
  min-width: 75px;

}

.github-Table-SearchReset {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 5px;
}


.githubWidget--HeaderWrap{
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1rem;
}

.githubWidgetHeader--AddNewBTN{
  height: 30px;
  max-width: 150px;
  background-color: var(--green);
  color: #eaeaea;
  font-weight: bold;
  flex: 1;
  margin-right: 25px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.githubWidgetHeader--AddNewBTN:hover{
  color: #232b36;
}

.githubWidgetHeader--Title{
  flex: 1;
  margin-left: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -0.5px;
  font-size: 1.4rem;    
}

.githubWidget--ContentWrap{
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  font-size: 1.25rem;
}

.githubWidget--LoadingWrap{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  flex-direction: column;
}

.githubWidget--LoadingWrapText{
  margin: 0px;
  color: var(--subtext-color)
}

.githubWidget--ErrorWrap{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--subtext-color);
  text-align: center;
}

.githubWidget--rowBTN{
  cursor: pointer;
  font-size: 1.2rem;
}

.githubWidget--rowBTN:hover{
  color: var(--green);
}

.githubWidget--rowBTNdelete{
  cursor: pointer;
  font-size: 1.2rem;
}

.githubWidget--rowBTNdelete:hover{
  color: var(--red);
}

.githubWidget--checkStatus{
  color: var(--red);
  font-weight: bold;
}

.githubWidget--offenStatus{
  color: var(--yellow);
  font-weight: bold;
}

.githubWidget--normalStatus{
  color: var(--green);
  font-weight: bold;
}



.createDowntimeWrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(90%);
  height: calc(90%);
  backdrop-filter: blur(3px);
  z-index: 499;
}


.createDowntime--Content{
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 80%;
  background-color: var(--bg-downtime);
  border-radius: 10px;
  border: 3px solid var(--grey);
  padding: 25px 50px 25px 50px;
  overflow: hidden;
  justify-content: space-between; /* neue Zeile hinzufügen */
}

.ticketModalInput{
  width: 100%;
  height: 25px;
  font-size: 13px;
  text-align: left;
  border: 1px solid #929292;
  border-radius: 2px;
  margin-top: 5px;    
}

.ticketModalSelect{
  width: 102%;
  height: 29px;
  font-size: 13px;
  text-align: left;
  border: 1px solid #929292;
  border-radius: 2px;
  margin-top: 5px;    
}