.mainAuthWrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;  
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    box-sizing: border-box; 
    background-color: white !important; 
  }
  
  .authContainer{
    align-self: center;
    height: 30%;
    width: 30%;
    top: 0;
    left: 0;
    overflow: hidden;  
    box-sizing: border-box;  
  }

  h1{
    flex-grow: 1;
    width: 100%;
  }
  