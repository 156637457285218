.headerWrap {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 60px;
    margin-right: 20px;
    background-color: var(--bg-dark);
    color: var(--text-color);
}

.header--DashboardInfo--Wrap{
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 300px;
    align-items: center;
    margin-left: 2px;
}

.header--DashboardInfo--Btn{
    width: 40px;
    height: 40px;
    font-size: 2.5rem;
    background-color: transparent;
    color: var(--text-color);
    border: none;
    margin-left: 13px;
}

.header--User--Wrap{
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 300px;    
}

.header--DashboardInfo--DashboardTitle{
    flex-shrink: 1;
    margin-left: 2px;
    padding-bottom: 10px;    
    margin-right: 20px;
    font-weight: bold;
    flex-basis: auto;
    min-width: 200px;
    color: var(--text-color);
}

.header--DashboardInfo--DashboardDesc{
    position: absolute;
    top: 38px;
    left: 10px;
    font-size: 1rem;
    color: var(--subtext-color);
    margin-left: 15px;
}

.header--User--Wrap{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
}

.header--User--Icon{
    margin-right: 5px; 
}

.header--User--Name{
    flex-basis: auto;
    color: var(--text-color);
    margin-right: 10px;
    font-size: 1.3rem;
}

.header--User--Btn{
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: var(--text-color);
    border: none;    
}

.header--User--Btn:hover{
    color: var(--green);
}