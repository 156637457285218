@import url('https://fonts.googleapis.com/css?family=Open+Sans');

:root {
  --text-color: #eaeaea;
  --text-color-dark: #1a202c;
  --subtext-color: #8d9197;
  --border-color: rgba(0,0,0,0.16);
  --red: #d83821;
  --green: #84bc34;
  --darkgreen: #67932a;
  --yellow: #e1ba1f;
  --grey: #464646;
  --bg-dark: #1a202c;
  --bg-menu: #252e3c;
  --bg-mainmenu: #344053;
  --bg-widget: #1c2431;
  --bg-downtime: #232b36;
  --hover-color: #334155;
  --main-menu-button-color: lightslategray;
  --main-menu-button-press-color: rgb(82, 93, 104);
  --main-menu-divider-color: #415d84;
  --main-menu-hover-color: #415068;
  --create-downtime-btn-hover-color: #3a803a;
  --cloudflare-graphheader:#232b36;
  --cloudflare-graphBG:rgba(35, 43, 54, 0.61);
  --downtime-creator-hover-color: #627ea4bd;
  --table-row-bg-color:  #252e3c;
  --table-row-bg-colorRED: #ffb6b6;  

  --scrollbarBG: #1c2431;
  --thumbBG: #39484f;
}

/* Die folgenden CSS variablen sind entgegen dem Namen jene für den Lightmode */
[data-theme='dark'] {
  --text-color: #343434;
  --text-color-dark: #1a202c;
  --subtext-color: #47494c;
  --border-color: rgba(0,0,0,0.16);
  --red: #d83821;
  --green: #84bc34;
  --darkgreen: #67932a;
  --yellow: #e1ba1f;
  --grey: #464646;
  --bg-dark: #eeeeee;
  --bg-menu: #f5f5f5;
  --bg-mainmenu: #e0e0e0;
  --bg-widget: #e3e3e3;
  --bg-downtime: #e3e3e3;
  --hover-color: #bababa;
  --main-menu-button-color: lightslategray;
  --main-menu-button-press-color: rgb(82, 93, 104);
  --main-menu-divider-color: #415d84;
  --main-menu-hover-color: #bababa;
  --create-downtime-btn-hover-color: #3a803a;  
  --cloudflare-graphheader:#dbdada;
  --cloudflare-graphBG:rgba(205, 205, 205, 0.463);
  --downtime-creator-hover-color: #989898;
  --table-row-bg-color: #eaeaea; 
  --table-row-bg-colorRED: #ffb6b6;  
  --table-row-bg-colorYELLOW: #f7e093;

  --scrollbarBG: #e3e3e3;
  --thumbBG: #becad2;
}



html {
  font-size: 62.5%;       
  height: 100%;
}

body {   
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;  
  height: 100%;
  background-color: var(--bg-dark);
  
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

*::-webkit-scrollbar {
  width: 11px;
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}


#root {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;  
  margin: 0px;  /* removes default style */
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

/* Inserting this collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row */
 .break {
  min-width: 100%;
  height: 0;
}


 /* The switch - the box around the slider */
 .switch {
  position: relative;
  display: inline-block;
  height: 15px; 
  margin-top: 10px;
  width: 33px;
  margin-right: none;
  margin-top: 6px;

}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 1px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2a8133;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2a8133;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} 

.popup-content{
  display: flex !important;
  width: auto !important;
}


